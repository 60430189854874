import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Sidebar,
  Button,
  Menu,
  Container,
  Grid,
  Image,
} from 'semantic-ui-react';

import { useAuth, useMedia } from 'providers';
import { routes } from 'config';

import CalculatorModal from 'components/CalculatorModal';
import LayoutHeader from './LayoutHeader';
import logoIcon from 'assets/images/logoIcon.svg';

const Comp = ({ children }) => {
  const { isUser } = useAuth();
  const { isTabletOrMobile } = useMedia();
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const mainMenuItems = (
    <>
      <Menu.Item link as={Link} to={routes.home}>
        <b>Home</b>
      </Menu.Item>

      <CalculatorModal
        trigger={
          <Menu.Item link>
            <b>Price Calculator</b>
          </Menu.Item>
        }
      />

      <Menu.Item link as={Link} to={routes.faq}>
        <b>FAQ</b>
      </Menu.Item>
    </>
  );

  const footerItems = (
    <>
      <Menu.Item link as={Link} to={routes.home}>
        <b>Home</b>
      </Menu.Item>

      <CalculatorModal
        trigger={
          <Menu.Item link>
            <b>Price Calculator</b>
          </Menu.Item>
        }
      />

      <Menu.Item link as={Link} to={routes.faq}>
        <b>FAQ</b>
      </Menu.Item>

      <Menu.Item link as={Link} to={routes.terms}>
        <b>Terms & Conditions</b>
      </Menu.Item>

      <Menu.Item link as={Link} to={routes.privacyPolicy}>
        <b>Privacy Policy</b>
      </Menu.Item>
    </>
  );

  const menuItems = (
    <>
      {mainMenuItems}

      {isUser ? (
        <Menu.Item name="dashboard">
          <Button
            fluid={isTabletOrMobile}
            as={Link}
            to={routes.dashboard}
            basic
            primary
          >
            <b>Dashboard</b>
          </Button>
        </Menu.Item>
      ) : (
        <>
          <Menu.Item name="sign-up">
            <Button
              fluid={isTabletOrMobile}
              as={Link}
              to={routes.signUp}
              basic
              primary
            >
              <b>Sign Up Free</b>
            </Button>
          </Menu.Item>

          <Menu.Item name="sign-in">
            <Button fluid={isTabletOrMobile} as={Link} to={routes.signIn} basic>
              <b>Sign In</b>
            </Button>
          </Menu.Item>
        </>
      )}
    </>
  );

  const content = (
    <>
      <LayoutHeader
        menuItems={menuItems}
        setSidebarVisible={setSidebarVisible}
      />
      {children}

      <div
        style={{
          background: '#1a1c1d',
          padding: '2rem 0',
        }}
      >
        <Container>
          <Grid stackable verticalAlign="middle" columns={3}>
            <Grid.Column width={2}>
              <Image alt="ExoHub" src={logoIcon} size="tiny" centered />
            </Grid.Column>
            <Grid.Column width={10}>
              <Menu
                inverted
                secondary
                widths={isTabletOrMobile ? 3 : undefined}
                size={isTabletOrMobile ? 'small' : undefined}
              >
                {footerItems}
              </Menu>
            </Grid.Column>
            <Grid.Column width={4}>
              <Menu inverted secondary widths={1}>
                <Menu.Menu position={isTabletOrMobile ? undefined : 'right'}>
                  <Menu.Item style={{ textAlign: 'center' }}>
                    {`© Exohub ${new Date().getFullYear()}`}
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    </>
  );

  return (
    <div style={{ height: '100vh' }}>
      {isTabletOrMobile ? (
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation="overlay"
            onHide={() => setSidebarVisible(false)}
            vertical
            visible={sidebarVisible}
          >
            {menuItems}
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarVisible}>{content}</Sidebar.Pusher>
        </Sidebar.Pushable>
      ) : (
        content
      )}
    </div>
  );
};

export default Comp;
