import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import { PrivateRoute } from 'components';
import { useAuth } from 'providers';

import { routes } from 'config';
import NotFound from 'screens/NotFound';
import Home from 'screens/Home';
import SignIn from 'screens/Auth/SignIn';
import SignUp from 'screens/Auth/SignUp';
import SignOut from 'screens/Auth/SignOut';
import Faq from 'screens/Faq';
import Terms from 'screens/Terms';
import PrivacyPolicy from 'screens/PrivacyPolicy';
import ForgetPassword from 'screens/Auth/ForgetPassword';
import ResetPassword from 'screens/Auth/ResetPassword';
import Dashboard from 'screens/Dashboard';
import Checkout from 'screens/Checkout';
import Orders from 'screens/Account/Orders';
import Order from 'screens/Account/Order';

const App = () => {
  const { isUser, userLoading } = useAuth();

  if (userLoading) {
    return <Loader active size="large" />;
  }

  return (
    <Switch>
      <Route exact path={routes.home}>
        {isUser ? <Redirect to={routes.signedInHome} /> : <Home />}
      </Route>

      <Route exact path={routes.signIn}>
        {isUser ? <Redirect to={routes.signedInHome} /> : <SignIn />}
      </Route>

      <Route exact path={routes.signUp} component={SignUp} />
      <Route exact path={routes.signOut} component={SignOut} />
      <Route exact path={routes.forgetPassword} component={ForgetPassword} />
      <Route exact path={routes.resetPassword} component={ResetPassword} />

      <Route exact path={routes.faq} component={Faq} />
      <Route exact path={routes.terms} component={Terms} />
      <Route exact path={routes.privacyPolicy} component={PrivacyPolicy} />

      <PrivateRoute exact path={routes.dashboard} component={Dashboard} />
      <PrivateRoute exact path={routes.checkout} component={Checkout} />
      <PrivateRoute exact path={routes.orderCompleted} component={Order} />
      <PrivateRoute exact path={routes.orders} component={Orders} />
      <PrivateRoute
        exact
        path={routes.order({ id: ':id' })}
        component={Order}
      />

      <Route component={NotFound} />
    </Switch>
  );
};

export default App;
